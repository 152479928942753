import styled from "styled-components";
import Button from "@material-ui/core/Button";

const CTAButton = styled(Button)`
width: 100%;
height: 60px;
margin-top: 10px;
margin-bottom: 5px;
background: #b4cc3a !important;
color: #FFFFFF;
font-family: Arial, Helvetica, sans-serif;
font-size: 32px;
letter-spacing: 0px;
font-weight:800;
line-height:30px;

&:disabled {
  color: #FFFFFF;
  opacity: 0.3;
}

`;

export default CTAButton;
